.dialog {
    .modal-card {
        max-width: 460px;
        width: auto;
        .modal-card-head {
            font-size: $size-5;
            font-weight: $weight-semibold;
        }
        .modal-card-body {
            .field {
                margin-top: 16px;
            }
            &.is-titleless {
                border-top-left-radius: $card-radius;
                border-top-right-radius: $card-radius;
            }
        }
        .modal-card-foot {
            justify-content: flex-end;
            .button {
                display: inline; // Fix Safari centering
                min-width: 5em;
                font-weight: $weight-semibold;
            }
        }
        @include tablet {
            min-width: 320px;
        }
    }
    &.is-small {
        .modal-card,
        .input,
        .button {
            @include control-small;
        }
    }
    &.is-medium {
        .modal-card,
        .input,
        .button {
            @include control-medium;
        }
    }
    &.is-large {
        .modal-card,
        .input,
        .button {
            @include control-large;
        }
    }
    &.has-custom-container {
        position: absolute;
    }
}
