$taginput-height: calc(2em - 1px) !default;

.taginput {
    .taginput-container {
        display: flex;
        &.is-focusable {
            @extend %input-textarea;
            padding-bottom: 0;
            padding-top: calc(0.275em - 1px);
            padding-left: 0;
            padding-right: 0;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-start;
            height: auto;
            cursor: text;
        }
        &:not(.is-focusable) {
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-start;
            height: auto;
            &.is-small {
                @include control-small;
            }
            &.is-medium{
                @include control-medium;
            }
            &.is-large {
                @include control-large;
            }
        }

        > .tag,
        > .tags {
            margin-left: 0.275rem;
            margin-bottom: calc(0.275em - 1px);
            font-size: 0.9em;
            height: $taginput-height;
            .tag {
                margin-bottom: 0;
                font-size: 0.9em;
                height: $taginput-height;
                &.is-delete {
                    width: $taginput-height;
                }
            }
        }

        .autocomplete {
            position: static;
            flex: 1;
            input {
                height: $taginput-height;
                margin-bottom: calc(0.275em - 1px);
                padding-top: 0;
                padding-bottom: 0;
                border: none;
                box-shadow: none;
                min-width: 8em;
                &:focus {
                    box-shadow: none !important;
                }
            }
            .icon {
                height: $taginput-height;
            }
            > .control.is-loading::after {
                top: 0.375em;
            }
        }
    }
}
