@import './src/sass/utilities/mixins'

$primary: #742775
$secondary: #1e98a7
$dark: #535353
$blue-ligth: #E7F2FF

$custom-colors: ('secondary': ($secondary, #fafafa))

$family-sans-serif: 'Lato', sans-serif

$title-color: $dark
$title-weight: 600

$hr-background-color: #C0CCDA