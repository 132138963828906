$icon-svg-width: auto !default;
$icon-svg-height: auto !default;

.icon {
    @include unselectable;
    cursor: inherit;
    svg {
        background-color: transparent;
        fill: currentColor;
        stroke-width: 0;
        stroke: currentColor;
        pointer-events: none;
        width: $icon-svg-width;
        height: $icon-svg-height;
    }
}
