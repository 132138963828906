.upload {
    position: relative;
    display: inline-flex;
    input[type=file] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        outline: none;
        cursor: pointer;
        z-index: -1;
    }
    .upload-draggable {
        cursor: pointer;
        padding: 0.25em;
        border: 1px dashed $grey-light;
        border-radius: $radius-large;
        &.is-disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
        &.is-loading {
            position: relative;
            pointer-events: none;
            opacity: 0.5;
            &:after {
                @include loader;
                top: 0;
                left: calc(50% - 1.5em);
                width: 3em;
                height: 3em;
                border-width: 0.25em;
            }
        }
        &:hover,
        &.is-hovered {
            @each $name, $pair in $file-colors {
                $color: nth($pair, 1);
                &.is-#{$name} {
                    border-color: $color;
                    background: rgba($color, 0.05);
                }
            }
        }
        &.is-expanded {
            width: 100%;
        }
    }
    &.is-expanded {
        width: 100%;
    }
}
// temporary IE 11 hack !!!
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .upload {
        input[type=file] {
            z-index: auto;
        }
        .upload-draggable + input[type=file] {
            z-index: -1;
        }
    }
}
