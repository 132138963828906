.menu {
    .menu-list {
        li > a {
            &.is-disabled {
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.5;
            }
        }
    }
}