$gap: 32px !default
$tablet: 769px !default
$desktop: 960px + (2 * $gap) !default

=mobile
  @media screen and (max-width: $tablet - 1px)
    @content

=tablet
  @media screen and (min-width: $tablet), print
    @content

=touch
  @media screen and (max-width: $desktop - 1px)
    @content

=desktop
  @media screen and (min-width: $desktop)
    @content