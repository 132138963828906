
$skeleton-color: $grey-lighter !default;
$skeleton-background: linear-gradient(90deg, $skeleton-color 25%, rgba($skeleton-color, 0.5) 50%, $skeleton-color 75%) !default;
$skeleton-border-radius: $radius !default;
$skeleton-duration: 1.5s !default;
$skeleton-margin-top: .5rem !default;

@mixin steps-size($size) {
    > .b-skeleton-item {
        line-height: $size;
    }
}

.b-skeleton {
    display: inline-flex;
    flex-direction: column;
    vertical-align: middle;
    width: 100%;
    > .b-skeleton-item {
        background: $skeleton-background;
        background-size: 400% 100%;
        width: 100%;
        &.is-rounded {
            border-radius: $skeleton-border-radius;
        }
        &::after {
            content: "\00a0";
        }
        + .b-skeleton-item {
            margin-top: $skeleton-margin-top;
        }
    }
    &.is-animated {
        > .b-skeleton-item {
            animation: skeleton-loading $skeleton-duration infinite;
        }
    }
    &.is-centered {
        align-items: center;
    }
    &.is-right {
        align-items: flex-end;
    }
    + .b-skeleton {
        margin-top: $skeleton-margin-top;
    }
    @include steps-size($size-normal);
    &.is-small {
        @include steps-size($size-small);
    }
    &.is-medium {
        @include steps-size($size-medium);
    }
    &.is-large {
        @include steps-size($size-large);
    }
}

@keyframes skeleton-loading {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}
