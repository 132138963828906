$notices-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04) !default;

$toast-border-radius: 2em !default;
$toast-opacity: 0.92 !default;
$toast-box-shadow: $notices-box-shadow !default;

$snackbar-background-color: $dark !default;
$snackbar-color: $dark-invert !default;
$snackbar-border-radius: $radius !default;
$snackbar-button-text-transform: uppercase !default;
$snackbar-box-shadow: $notices-box-shadow !default;

.notices {
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2em;
    overflow: hidden;
    z-index: 1000;
    pointer-events: none;
    .toast {
        display: inline-flex;
        animation-duration: $speed-slow;
        margin: 0.5em 0;
        text-align: center;
        box-shadow: $toast-box-shadow;
        border-radius: $toast-border-radius;
        padding: 0.75em 1.5em;
        pointer-events: auto;
        opacity: $toast-opacity;
        @each $name, $pair in $notification-colors {
            $color: nth($pair, 1);
            $color-invert: nth($pair, 2);
            &.is-#{$name} {
                color: $color-invert;
                background: $color;
            }
        }
    }
    .snackbar {
        display: inline-flex;
        align-items: center;
        justify-content: space-around;
        animation-duration: $speed-slow;
        margin: 0.5em 0;
        box-shadow: $snackbar-box-shadow;
        border-radius: $snackbar-border-radius;
        pointer-events: auto;
        background: $snackbar-background-color;
        color: $snackbar-color;
        min-height: 3em;
        .text {
            padding: 0.5em 1em;
        }
        .action {
            margin-left: auto;
            padding: 0.5em;
            padding-left: 0;
            .button {
                font-weight: $weight-semibold;
                text-transform: $snackbar-button-text-transform;
                background: $snackbar-background-color;
                border: transparent;
            }
            .button:hover {
                background: darken($snackbar-background-color, 5%);
            }
            .button:active {
                background: darken($snackbar-background-color, 5%);
            }
            @each $name, $pair in $notification-colors {
                $color: nth($pair, 1);
                &.is-#{$name} {
                    .button {
                        color: $color;
                    }
                }
            }
        }
        @include mobile {
            width: 100%;
            margin: 0;
            border-radius: 0;
        }
        @include tablet {
            min-width: 350px;
            max-width: 600px;
            overflow: hidden;
        }
    }
    .notification {
        max-width: 600px;
    }

    .toast,
    .snackbar,
    .notification {
        // Modifiers
        &.is-top, &.is-bottom {
            align-self: center;
        }
        &.is-top-right, &.is-bottom-right {
            align-self: flex-end;
        }
        &.is-top-left, &.is-bottom-left {
            align-self: flex-start;
        }
        &.is-toast {
            opacity: $toast-opacity;
        }
    }

    // Modifiers
    &.is-top {
        flex-direction: column;
    }
    &.is-bottom {
        flex-direction: column-reverse;

        // Since the columns are reversed, we need to reverse the margin logic from
        // :not(:last-child) to :not(:first-child)
        .notification {
            margin-bottom: 0;
            &:not(:first-child) {
                margin-bottom: 1.5rem;
            }
        }
    }
    &.has-custom-container {
        position: absolute;
    }

    @include mobile {
        padding: 0;
        position: fixed !important;
    }
}
